import React from 'react';
import PropTypes from 'prop-types';

const SEOBreadcrumbs = ({ links }) => {
    function createJsonLD() {
        const jsonLD = {
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: links.map(
                (link, index) => ({
                    '@type': 'ListItem',
                    position: index + 1,
                    name: link.title,
                    item: link.url,
                }),
            ),
        };
        return { __html: JSON.stringify(jsonLD) };
    }

    // To avoid React HTML escaping
    // eslint-disable-next-line
    return <script type="application/ld+json" dangerouslySetInnerHTML={createJsonLD()} />;
};

SEOBreadcrumbs.propTypes = {
    links: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string,
            title: PropTypes.string,
        })),
};

SEOBreadcrumbs.defaultProps = {
    links: [],
};

export default SEOBreadcrumbs;
