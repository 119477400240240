import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { sprintf } from 'sprintf-js';

import env from '../../env';
import { urlWrapper } from '../../utils/urlWrapperUtils';
import SEOBreadcrumbs from './../Breadcrumbs/SEOBreadcrumbs';
import Icon from '../Icon';

import messagesDe from './translations/de.json';
import messagesEn from './translations/en.json';

import './styles.scss';

const messages = {
    de: messagesDe,
    en: messagesEn,
};

const SHARE_PRINT = 'print';
const SHARE_MAILTO = 'mailto';

/**
 *
 * @visibleName Social Media + Breadcrumb + Intro
 */
class SocialBreadcrumb extends React.Component {
    constructor(props) {
        super(props);
        this.handleShareClick = this.handleShareClick.bind(this);
    }

    handleShareClick(event) {
        /**
         * Variants to process:
         *  - link to share can be passed from the CMS for SSR render and animation
         *  - page generated in block editor and must take link from window
         */
        const shareId = event.currentTarget ? event.currentTarget.id : null;

        if (shareId === SHARE_PRINT && window) {
            event.preventDefault();
            window.print();
            return false;
        }

        if (shareId && env.SHARE_BY[shareId]) {
            const { linkToShare } = this.props;
            const windowHref = (typeof window !== 'undefined') && window.location ? window.location.href : null;
            const shareTarget = linkToShare || windowHref || env.DEFAULT_LINK_TO_SHARE;

            const shareMailSubject = shareId === SHARE_MAILTO ?
                event.currentTarget.dataset.subject : null;

            const clickLocation = sprintf(env.SHARE_BY[shareId], shareTarget, shareMailSubject);
            if (shareId === SHARE_MAILTO) {
                /* eslint-disable-next-line no-param-reassign */
                event.currentTarget.href = clickLocation;
                return false;
            }

            window.open(
                clickLocation,
                '_blank',
                'location=yes,height=570,width=520,scrollbars=yes,status=yes',
            );
        }
        return false;
    }

    render() {
        const {
            isLikeEnable,
            language,
            printLink,
            includeSEOBreadcrumb,
        } = this.props;

        const className = 'social-breadcrumb';

        const BREADCRUMB_LINKS_AMOUNT = 4;
        let links = [];
        for (let i = 1; i <= BREADCRUMB_LINKS_AMOUNT; i += 1) {
            if (this.props[`text${i}`] && this.props[`url${i}`]) {
                links = links.concat([{
                    title: this.props[`text${i}`],
                    url: this.props[`url${i}`],
                    isLast: false,
                }]);
            }
        }
        if (links.length) {
            links[links.length - 1].isLast = true;
        }

        const shareMailSubject = messages[language]['SocialBreadcrumb.share.mailto.subject'];

        return (
            <IntlProvider messages={messages[language]} locale={language}>
                <div className="g-container">
                    <div className="g-row">
                        <div className="g-col-xl-12 g-col-lg-6 g-col-md-4 g-col-sm-2">
                            <div className={className}>
                                <div className={`${className}__social-wrapper`}>
                                    <dl>
                                        <dt>
                                            <FormattedMessage
                                                id="SocialBreadcrumb.share"
                                                defaultMessage="share"
                                            />
                                        </dt>
                                        <dd>
                                            <a
                                                id={'facebook'}
                                                onClick={this.handleShareClick}
                                                href={'#share_facebook'}
                                                rel="noopener noreferrer"
                                                target="_blank"
                                                className={`${className}__icon`}
                                            >
                                                <Icon icon="estm_eng_share_fb" />
                                            </a>
                                            <a
                                                id={'twitter'}
                                                onClick={this.handleShareClick}
                                                href={'#share_twitter'}
                                                rel="noopener noreferrer"
                                                target="_blank"
                                                className={`${className}__icon`}
                                            >
                                                <Icon icon="estm_eng_share_twitter" />
                                            </a>
                                            <a
                                                id={SHARE_MAILTO}
                                                onClick={this.handleShareClick}
                                                href={`mailto:?subject=${shareMailSubject}`}
                                                data-subject={shareMailSubject}
                                                rel="noopener noreferrer"
                                                target="_self"
                                                className={`${className}__icon`}
                                            >
                                                <Icon icon="estm_eng_share_mail" />
                                            </a>
                                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                            {printLink ?
                                                <a
                                                    href={printLink}
                                                    className={`${className}__icon ${className}__icon--print`}
                                                >
                                                    <Icon icon="estm_eng_share_print" />
                                                </a>
                                                :
                                                <a
                                                    id={SHARE_PRINT}
                                                    onClick={this.handleShareClick}
                                                    href="#print"
                                                    rel="noopener noreferrer"
                                                    target="_self"
                                                    className={`${className}__icon ${className}__icon--print`}
                                                >
                                                    <Icon icon="estm_eng_share_print" />
                                                </a>
                                            }
                                        </dd>
                                    </dl>
                                    {isLikeEnable &&
                                    <div className={`${className}__favourite`}>
                                        <span><FormattedMessage
                                            id="SocialBreadcrumb.favourite"
                                            defaultMessage="merken"
                                        /></span>
                                        <Icon icon="like" />
                                    </div>
                                    }
                                </div>
                                <div className={`${className}__breadcrumb`}>
                                    {
                                        links.map((link, index) => (
                                            <a
                                                key={`sb:${index + 1}:${link.url}`}
                                                className={`${className}__breadcrumbItem`}
                                                href={urlWrapper(link.url)}
                                            >
                                                {link.title}{' '}
                                                {!link.isLast ? <Icon icon="estm_eng_chevron_thick" /> : ''}
                                            </a>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {includeSEOBreadcrumb && <SEOBreadcrumbs links={links} />}
                </div>
            </IntlProvider>
        );
    }
}

SocialBreadcrumb.formFields = Object.assign({}, {
    text1: <input />,
    url1: <input />,
    text2: <input />,
    url2: <input />,
    text3: <input />,
    url3: <input />,
    text4: <input />,
    url4: <input />,

});
/* eslint-disable react/no-unused-prop-types */
SocialBreadcrumb.propTypes = {
    includeSEOBreadcrumb: PropTypes.bool,
    isLikeEnable: PropTypes.bool,
    language: PropTypes.string,
    linkToShare: PropTypes.string,
    printLink: PropTypes.string,
    //
    text1: PropTypes.string,
    url1: PropTypes.string,
    text2: PropTypes.string,
    url2: PropTypes.string,
    text3: PropTypes.string,
    url3: PropTypes.string,
    text4: PropTypes.string,
    url4: PropTypes.string,
};

SocialBreadcrumb.defaultProps = {
    includeSEOBreadcrumb: true,
    isLikeEnable: false,
    language: 'de',
    linkToShare: null,
    printLink: '',
    //
    text1: 'Home',
    url1: '/',
    text2: '',
    url2: '',
    text3: '',
    url3: '',
    text4: '',
    url4: '',
};

export default SocialBreadcrumb;
